/* .brdr{
    height: 4px!important;
    background-color: red!important;
    width: 100%!important;
}
*/
h1{
    background-color: #f3f2f2!important;
    padding: 40px;
} 
.quote{
    background-color: green!important;
    width:100%!important;
    height: 50vh;
}